import React, { useContext, useEffect, useState } from 'react';
import {
    DefaultEffects,
    Nav,
    INavLink,
    INavStyles,
    INavLinkGroup,
    IconButton,
    IIconStyles,
} from '@fluentui/react';
import { routeKeys, getNavRoute } from '../../../Common/util/RouteUtils';
import { useLocation, Link } from 'react-router-dom';
import { AuthContext } from '../../models/AuthContext';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';
import { ThemeContext } from '../../models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

export const PageNav: React.FC = () => {
    const location = useLocation();
    const { authContext } = useContext(AuthContext);
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [isHidden, setIsHidden] = useState<boolean>(false);
    const [selectedRouteKey, setSelectedRouteKey] = useState<
        string | undefined
    >(getNavRoute(location.pathname));

    useEffect(() => {
        setSelectedRouteKey(getNavRoute(location.pathname));
    }, [location]);

    const collapseChevronStyles: IIconStyles = {
        root: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
        },
    };

    const navStyles: Partial<INavStyles> = {
        chevronIcon: {
            display: 'none',
        },
        chevronButton: {
            display: 'none',
        },
        linkText: {
            display: isHidden ? 'none' : 'block',
        },
    };

    // Use capabilities context
    const capabilities = useContext(CapabilityContext);
    // Only show the service-oriented pages if user has Beta UI role and we are ready to show them
    const showServiceOrientedPages = false; //capabilities.check(Capability.betaUIFeaturesRole);
    // Only show Admin if user has Bridge.Admin role
    const showAdmin = capabilities.check(Capability.bridgeAdminRole);

    const _links: INavLink[] = [
        {
            name: 'Home',
            url: routeKeys.Home,
            ariaLabel: 'Home',
            icon: 'HomeSolid',
            isExpanded: true,
            key: routeKeys.Home,
        },
    ];

    if (authContext.isUserAuthorized) {
        let featureSubLinks: INavLink[] = [
            {
                name: 'Releases',
                url: routeKeys.Features.Releases.Latest,
                ariaLabel: 'Releases',
                icon: 'Rocket',
                isExpanded: true,
                key: routeKeys.Features.Releases.Base,
            },
            {
                name: 'Builds',
                url: routeKeys.Features.Builds.Latest,
                ariaLabel: 'Builds',
                icon: 'AllApps',
                isExpanded: true,
                key: routeKeys.Features.Builds.Base,
            },
        ];

        _links.push({
            name: 'Features',
            url: routeKeys.Features.Releases.Latest,
            ariaLabel: 'Features',
            icon: 'Product',
            isExpanded: true,
            key: routeKeys.Features.Base,
            links: featureSubLinks,
        });

        if (showServiceOrientedPages) {
            _links.push(
                {
                    name: 'Separator',
                    url: '',
                },
                {
                    name: 'Services',
                    url: routeKeys.Services,
                    ariaLabel: 'Services',
                    icon: 'AllApps',
                    isExpanded: true,
                    key: routeKeys.Services,
                },
                {
                    name: 'Organizations',
                    url: routeKeys.Organizations,
                    ariaLabel: 'Organizations',
                    icon: 'WorkforceManagement',
                    isExpanded: true,
                    key: routeKeys.Organizations,
                }
            );
        }
    }

    if (showAdmin) {
        _links.push(
            {
                name: 'Separator',
                url: '',
            },
            {
                name: 'Administration',
                url: routeKeys.Administration,
                ariaLabel: 'Administration',
                icon: 'Settings',
                isExpanded: true,
                key: routeKeys.Administration,
            }
        );
    }

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: _links,
        },
    ];

    const chevronClick = () => {
        setIsHidden(!isHidden);
    };

    return (
        <div
            className="PageNav-root"
            style={{
                boxShadow: DefaultEffects.elevation8,
                backgroundColor: theme.palette.neutralQuaternaryAlt,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                zIndex: 2,
            }}
        >
            <div>
                <Nav
                    styles={navStyles}
                    ariaLabel="Navigation menu"
                    groups={navLinkGroups}
                    selectedKey={selectedRouteKey}
                    linkAs={(props) => {
                        if (props.link!.name === 'Separator') {
                            return (
                                <hr
                                    style={{
                                        color: 'gray',
                                        margin: '0 15px',
                                    }}
                                />
                            );
                        }
                        return (
                            <Link
                                className={props.className}
                                style={{ boxSizing: 'border-box' }}
                                to={props.href!}
                            >
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingTop: isHidden ? '5px' : '0px',
                                    }}
                                >
                                    {!!props.iconProps && (
                                        <IconButton
                                            iconProps={props.iconProps}
                                        />
                                    )}
                                    {props.children}
                                </span>
                            </Link>
                        );
                    }}
                />
            </div>

            <IconButton
                styles={collapseChevronStyles}
                iconProps={{
                    iconName: isHidden
                        ? 'DoubleChevronLeftMedMirrored'
                        : 'DoubleChevronLeftMed',
                }}
                onClick={chevronClick}
            />
        </div>
    );
};
