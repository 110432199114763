import React, { useCallback, useContext } from 'react';
import { Artifact } from '../../../../models/Artifact';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { IconButton, IStackTokens, Stack, Text } from '@fluentui/react';
import { CompletionIndicatorIcon } from '../../../MultistageReleaseData/Display/Status/CompletionIndicatorIcon';
import { Ellipsis } from '../../../../../HubLayout/components/Utilities/Ellipsis';
import { dateLocaleStringWithTimeZone } from '../../../../../Common/util/DateUtils';
import { getManifestDownload } from '../../../../api/AGSSApi';
import { getArtifactDetailsPath } from '../../../../util/BuildUtils';
import { InternalLink } from '../../../../../Common/components/Links';

interface IMobileArtifactInfoProps {
    artifact: Artifact;
}

export const MobileArtifactInfo: React.FC<IMobileArtifactInfoProps> = (
    props: IMobileArtifactInfoProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const stackTokens: IStackTokens = { childrenGap: 10 };

    const getArtifactManifestCallback = useCallback(
        async (artifactId: string) => {
            const result = await getManifestDownload(artifactId);
            return result;
        },
        [props.artifact.artifactId]
    );

    return (
        <div className="MobileArtifactInfo-root">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    border: 'solid 1px ' + theme.palette.neutralLighterAlt,
                    margin: '5px 0px',
                }}
            >
                <Stack
                    tokens={stackTokens}
                    style={{
                        width: '100%',
                    }}
                >
                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Stack tokens={stackTokens}>
                            <InternalLink
                                value={props.artifact.artifactId || ''}
                                url={
                                    '#' +
                                    getArtifactDetailsPath(
                                        props.artifact.artifactId || ''
                                    )
                                }
                                title="View hydration manifest details"
                            />
                            <Stack horizontal tokens={{ childrenGap: 6 }}>
                                <CompletionIndicatorIcon
                                    completionIndicator={
                                        props.artifact.completionIndicator
                                    }
                                    theme={theme}
                                    showText
                                />
                            </Stack>
                        </Stack>

                        <Stack
                            horizontal
                            tokens={{ childrenGap: 6 }}
                            style={{
                                alignItems: 'center',
                            }}
                        >
                            <Ellipsis
                                menuItems={[
                                    {
                                        key: 'downloadManifest',
                                        text: 'Download Hydration Manifest',
                                        iconProps: { iconName: 'Download' },
                                        onClick: () => {
                                            getArtifactManifestCallback(
                                                props.artifact.artifactId ?? ''
                                            );
                                        },
                                    },
                                ]}
                            />
                        </Stack>
                    </Stack>

                    {/* Info */}
                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text variant="small">
                            {props.artifact.releaseName}
                        </Text>
                        <Text variant="small">
                            {props.artifact.environmentId}
                        </Text>
                    </Stack>

                    <Stack
                        horizontal
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        <Text variant="small">
                            Files Replicated:{' '}
                            {props.artifact.totalFilesTransferred}
                        </Text>
                        <Text variant="small">
                            Bytes Replicated:{' '}
                            {props.artifact.totalBytesReplicated}
                        </Text>
                    </Stack>

                    <Text variant="small">
                        {dateLocaleStringWithTimeZone(props.artifact.createdOn)}
                    </Text>
                </Stack>
            </div>
        </div>
    );
};
