import React, { useCallback, useContext, useState } from 'react';
import { DefaultHubButton } from '../../../HubLayout/components/Utilities/DefaultHubButton';
import { getManifestDownload } from '../../api/AGSSApi';
import { Artifact } from '../../models/Artifact';
import { usePromise } from '../../../Common/hooks/usePromise';
import {
    CommandButton,
    IStackTokens,
    Icon,
    Spinner,
    Stack,
} from '@fluentui/react';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';

interface IDownloadManifestButtonProps {
    artifact?: Artifact;
    isMenuItem?: boolean;
}

export const DownloadManifestButton: React.FC<IDownloadManifestButtonProps> = (
    props: IDownloadManifestButtonProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const stackTokens: IStackTokens = { childrenGap: 10 };

    const getArtifactManifestCallback = useCallback(async () => {
        const result = await getManifestDownload(
            props.artifact?.artifactId ?? ''
        );
        setIsLoading(false);
        return result;
    }, [props.artifact?.artifactId]);

    const [result, error, isLoaded, start] = usePromise(
        getArtifactManifestCallback,
        false
    );

    const handleManifestDownloadClick = () => {
        setIsLoading(true);
        start();
    };

    return (
        <div className="DownloadManifestButton-root">
            <Stack
                horizontal
                tokens={stackTokens}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {!isLoading ? (
                    props.isMenuItem ? (
                        <CommandButton
                            iconProps={{ iconName: 'Download' }}
                            onClick={handleManifestDownloadClick}
                        />
                    ) : (
                        <DefaultHubButton
                            buttonText="Download Hydration Manifest"
                            icon="Download"
                            onClick={handleManifestDownloadClick}
                        />
                    )
                ) : (
                    <Spinner />
                )}

                {(result !== undefined || error) && !isLoading && (
                    <Icon
                        iconName={
                            result ? 'SkypeCircleCheck' : 'StatusErrorFull'
                        }
                        style={{
                            color: result
                                ? theme.semanticColors.successIcon
                                : theme.semanticColors.errorIcon,
                        }}
                    />
                )}
            </Stack>
        </div>
    );
};
