import React, { useCallback, useContext, useState, useEffect } from 'react';
import { CancelToken } from 'axios';
import {
    AgrmType,
    checkValidCloudOrEnvironment,
    getAgrmData,
    getAgrmOrchestrations,
    getAgrmStates,
} from '../../api/AGRMApi';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../Common/api/ABHub/ABHubGlobalConfig';
import {
    CompoundButton,
    DirectionalHint,
    Icon,
    IContextualMenuItem,
    IContextualMenuProps,
    Stack,
} from '@fluentui/react';
import { JsonModal } from '../../../HubLayout/components/JsonModal';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { usePromise } from '../../../Common/hooks/usePromise';

interface IAgrmContextButtonProps {
    uniqueIdentifier?: string;
    type: AgrmType;
}


export const AgrmContextButton: React.FC<IAgrmContextButtonProps> = (
    props: IAgrmContextButtonProps
) => {
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const [openModal, setOpenModal] = useState(false);
    const [jsonModalData, setJsonModalData] = useState<string>('');
    const [jsonModalHeader, setJsonModalHeader] = useState<string>('');
    const [items, setItems] = useState<IContextualMenuItem[]>([]);
    const [error, setError] = useState<string>('');

    const contextButtonStyles = {
        menuIcon: {
            display: 'none',
        },
        root: {
            paddingTop: '0px',
            paddingRight: '5px',
            paddingBottom: '0px',
            paddingLeft: '5px',
            maxHeight: '40px',
            minHeight: '40px',
            border: 'none',
            backgroundColor: theme.palette.neutralLighter,
        },
    };

    const hideModal = () => {
        setOpenModal(false);
        setJsonModalData('');
        setError('');
    };

    const environment = getConfig(GlobalConfigProperties.Environment);
    const cloud = getConfig(GlobalConfigProperties.Cloud);

    const isValidEnv = (cld: string, env: string) => {
        try {
            checkValidCloudOrEnvironment(cld, env);
            return true;
        } catch {
            return false;
        }
    };

    const openModalBehavior = (header: string, stateFunction: Function) => {
        setOpenModal(true);
        setJsonModalHeader(header);
        stateFunction();
    };

    const getAgrmApiCall = async (
        apiCall: Function,
        cancelToken?: CancelToken
    ) => {
        if (props.uniqueIdentifier) {
            const result = await apiCall(
                props.uniqueIdentifier,
                props.type,
                cancelToken
            );
            const stringified = JSON.stringify(result, null, 2);
            setJsonModalData(stringified);
        } else {
            throw new Error(
                'Failed to get Agrm Information. Invalid Unique Identifier.'
            );
        }
    };

    const setGeneralData = useCallback(
        () => getAgrmApiCall(getAgrmData),
        [props.uniqueIdentifier]
    );
    const setAgrmOrchestrations = useCallback(
        () => getAgrmApiCall(getAgrmOrchestrations),
        [props.uniqueIdentifier]
    );
    const setAgrmStates = useCallback(
        () => getAgrmApiCall(getAgrmStates),
        [props.uniqueIdentifier]
    );

    const [, generalDataError, generalDataIsLoaded, generalDataStart] =
        usePromise(setGeneralData);

    const [, orchestrationDataError, orchestrationDataIsLoaded, orchestrationDataStart,] =
        usePromise(setAgrmOrchestrations);

    const [, stateDataError, stateDataIsLoaded, stateDataStart] =
        usePromise(setAgrmStates);

    useEffect(() => {
        if (generalDataError) {
            setError(generalDataError.message);
        } else if (orchestrationDataError) {
            setError(orchestrationDataError.message);
        } else if (stateDataError) {
            setError(stateDataError.message);
        }
    }, [generalDataError, orchestrationDataError, stateDataError]);

    useEffect(() => {
        if (isValidEnv(cloud, environment)) {
            var temp = [];
            temp.push({
                key: 'general',
                text: 'General ' + props.type + ' Data',
                iconProps: { iconName: 'KnowledgeArticle' },
                onClick: () => {
                    openModalBehavior(
                        props.type + ' Information',
                        generalDataStart
                    );
                },
            });

            temp.push({
                key: 'orchestrations',
                text: props.type + ' Orchestrations',
                iconProps: { iconName: 'ServerProcesses' },
                onClick: () => {
                    openModalBehavior(
                        props.type + 'Orchestrations',
                        orchestrationDataStart
                    );
                },
            });

            temp.push({
                key: 'states',
                text: props.type + ' States',
                iconProps: { iconName: 'Org' },
                onClick: () => {
                    openModalBehavior(
                        props.type + ' States',
                        stateDataStart);
                },
            });

            setItems(temp);
        }
    }, [props.uniqueIdentifier]);

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        directionalHintFixed: true,
        items: items,
    };

    return isValidEnv(cloud, environment) ? (
        <div className="AgrmContextButton-root">
            <CompoundButton menuProps={menuProps} styles={contextButtonStyles}>
                <Icon iconName="MoreVertical" />
            </CompoundButton>
            <JsonModal
                jsonData={error !== '' ? error : jsonModalData ?? ''}
                header={jsonModalHeader ?? ''}
                isOpen={openModal}
                closeModal={hideModal}
                dataLoaded={
                    generalDataIsLoaded ||
                    orchestrationDataIsLoaded ||
                    stateDataIsLoaded ||
                    error !== ''
                }
            />
        </div>
    ) : null;
};
