import {
    INavLink,
    INavLinkGroup,
    INavStyles,
    IconButton,
    Nav,
    Panel,
    PanelType,
} from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { AuthContext } from '../../../models/AuthContext';
import { ThemeContext } from '../../../models/ThemeContext';
import { getNavRoute, routeKeys } from '../../../../Common/util/RouteUtils';
import { CapabilityContext } from '../../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../../Common/components/Capabilities/capability';

interface IMobilePageNavProps {
    isOpen: boolean;
    dismissPanel:
        | ((
              ev?:
                  | React.SyntheticEvent<HTMLElement, Event>
                  | KeyboardEvent
                  | undefined
          ) => void)
        | undefined;
}

export const MobilePageNav: React.FC<IMobilePageNavProps> = (
    props: IMobilePageNavProps
) => {
    const location = useLocation();
    const { authContext } = useContext(AuthContext);
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const [selectedRouteKey, setSelectedRouteKey] = useState<
        string | undefined
    >(getNavRoute(location.pathname));

    useEffect(() => {
        setSelectedRouteKey(getNavRoute(location.pathname));
        if (props && props.dismissPanel) {
            props.dismissPanel();
        }
    }, [location]);

    // Use capabilities context
    const capabilities = useContext(CapabilityContext);
    // Only show Admin if user has Bridge.Admin role
    const showAdmin = capabilities.check(Capability.bridgeAdminRole);

    const navStyles: Partial<INavStyles> = {
        chevronIcon: {
            display: 'none',
        },
        chevronButton: {
            display: 'none',
        },
    };

    const [links, setLinks] = useState<INavLink[]>([
        {
            name: 'Home',
            url: routeKeys.Home,
            ariaLabel: 'Home',
            icon: 'HomeSolid',
            isExpanded: true,
            key: routeKeys.Home,
        },
    ]);

    useEffect(() => {
        let newLinks = [...links];
        if (authContext.isUserAuthorized) {
            let featureSubLinks: INavLink[] = [
                {
                    name: 'Releases',
                    url: routeKeys.Features.Releases.Latest,
                    ariaLabel: 'Releases',
                    icon: 'Rocket',
                    isExpanded: true,
                    key: routeKeys.Features.Releases.Base,
                },
                {
                    name: 'Builds',
                    url: routeKeys.Features.Builds.Latest,
                    ariaLabel: 'Builds',
                    icon: 'AllApps',
                    isExpanded: true,
                    key: routeKeys.Features.Builds.Base,
                },
            ];

            newLinks.push({
                name: 'Features',
                url: routeKeys.Features.Releases.Latest,
                ariaLabel: 'Features',
                icon: 'Product',
                isExpanded: true,
                key: routeKeys.Features.Base,
                links: featureSubLinks,
            });
        }

        if (showAdmin) {
            newLinks.push(
                {
                    name: 'Separator',
                    url: '',
                },
                {
                    name: 'Administration',
                    url: routeKeys.Administration,
                    ariaLabel: 'Administration',
                    icon: 'Settings',
                    isExpanded: true,
                    key: routeKeys.Administration,
                }
            );
        }
        setLinks(newLinks);
    }, [authContext.isUserAuthorized, showAdmin]);

    const navLinkGroups: INavLinkGroup[] = [
        {
            links: links,
        },
    ];

    return (
        <div className="MobileNavPanel-root">
            <Panel
                isLightDismiss
                isOpen={props.isOpen}
                onDismiss={props.dismissPanel}
                closeButtonAriaLabel="Close"
                headerText="Navigation"
                type={PanelType.customNear}
            >
                <div>
                    <Nav
                        styles={navStyles}
                        ariaLabel="Navigation menu"
                        groups={navLinkGroups}
                        selectedKey={selectedRouteKey}
                        linkAs={(props) => {
                            if (props.link!.name === 'Separator') {
                                return (
                                    <hr
                                        style={{
                                            color: 'gray',
                                            margin: '0 15px',
                                        }}
                                    />
                                );
                            }
                            return (
                                <Link
                                    className={props.className}
                                    style={{ boxSizing: 'border-box' }}
                                    to={props.href!}
                                >
                                    <span
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {!!props.iconProps && (
                                            <IconButton
                                                iconProps={props.iconProps}
                                            />
                                        )}
                                        {props.children}
                                    </span>
                                </Link>
                            );
                        }}
                    />
                </div>
            </Panel>
        </div>
    );
};
