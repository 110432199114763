import { ServiceType } from '../../ABSM/models/ServiceType';

//For service-oriented Hub
export const getArtifactPath = (
    artifactId: string,
    serviceId: string,
    serviceType: ServiceType
): string => {
    const artifactUrl = `${serviceId}/Artifacts/${artifactId}`;
    switch (serviceType) {
        case 'Component':
            return `/Components/${artifactUrl}`;
        case 'Service':
            return `/Services/${artifactUrl}`;
        case 'Organization':
            return `/Organizations/${artifactUrl}`;
        case 'Division':
            return `/Divisions/${artifactUrl}`;
    }
};

export const getArtifactDetailsPath = (artifactId: string): string => {
    return `/BuildStatus/Artifact/${artifactId}`;
};

//For service-oriented Hub
export const getBuildPath = (
    buildInstanceCorrelationId: string,
    serviceId: string,
    serviceType: ServiceType
): string => {
    const buildUrl = `${serviceId}/Builds/${buildInstanceCorrelationId}`;
    switch (serviceType) {
        case 'Component':
            return `/Components/${buildUrl}`;
        case 'Service':
            return `/Services/${buildUrl}`;
        case 'Organization':
            return `/Organizations/${buildUrl}`;
        case 'Division':
            return `/Divisions/${buildUrl}`;
    }
};

export const getBuildDetailsPath = (
    buildInstanceCorrelationId: string
): string => {
    let url = `/BuildStatus/Build/${buildInstanceCorrelationId}`;
    return url;
};

export const getAdoRepoUrl = (
    accountName: string,
    projectName: string,
    repositoryName: string
): string => {
    let url: string = process.env.REACT_APP_ADO_URL_REPO || '';
    url = url.replace('{accountName}', accountName ? accountName : '');
    url = url.replace('{projectName}', projectName ? projectName : '');
    url = url.replace('{repoName}', repositoryName ? repositoryName : '');
    return url;
};
