import React, {
    Dispatch,
    SetStateAction,
    useCallback,
    useContext,
} from 'react';
import {
    Stack,
    MessageBar,
    MessageBarType,
    IStackProps,
    Spinner,
    SpinnerSize,
    CommandButton,
} from '@fluentui/react';
import { CancelToken } from 'axios';
import { replicateBuild, getArtifactDetails } from '../../../AGSS/api/AGSSApi';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';
import { ActionButton } from '../../../Common/components/ActionButton';
import { Artifact } from '../../models/Artifact';

// need theme info for MessageBar
// it doesn't alwasys respond well to theme change
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';

interface IProps {
    resetChoice?: () => void;
}

export interface IReplicateFullBuildProps {
    addReplication?: () => void;
    artifact?: Artifact;
    setCompletionMessage?: Dispatch<SetStateAction<string>>;
    isMenuItem?: boolean;
}

export const ReplicateFullBuildButton: React.FC<IReplicateFullBuildProps> = (
    props: IReplicateFullBuildProps
) => {
    // User must have Artifact.Rereplicate role to see the button
    const capabilities = useContext(CapabilityContext);

    const [messageBarChoice, setMessageBarChoice] = React.useState<
        string | undefined
    >(undefined);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
        undefined
    );
    const [working, setWorking] = React.useState<boolean>();

    const resetChoice = useCallback(() => setMessageBarChoice(undefined), []);

    // MessageBar theme update
    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);
    const messageBarStylesSuccess = {
        root: {
            color: theme.semanticColors.bodyText,
        }
    };
    const stackProps: IStackProps = {
        grow: true,
        styles: { root: { overflow: 'hidden', width: '100%' } },
        tokens: { childrenGap: 10 },
        horizontal: true,
    };
    const ErrorExample = (p: IProps) => (
        <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            onDismiss={p.resetChoice}
            dismissButtonAriaLabel="Close"
        >
            {errorMessage}
        </MessageBar>
    );
    const SuccessExample = (p: IProps) => (
        <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
            truncated={false}
            onDismiss={p.resetChoice}
            dismissButtonAriaLabel="Close"
            styles={messageBarStylesSuccess}
        >
            Successfully started the build replication.
        </MessageBar>
    );

    // Setup replicate build publish
    const publishPromise = useCallback(
        async (cancelToken?: CancelToken) => {
            resetChoice();
            setWorking(true);
            if (props.artifact?.artifactId) {
                const artifactId = props.artifact.artifactId;
                const artifactDetails = await getArtifactDetails(artifactId, cancelToken);

                var pipeline = '';
                if (artifactDetails.pipeline != null) {
                    pipeline = artifactDetails.pipeline;
                }

                const data = await replicateBuild(artifactId, pipeline);
                if (data.response.succeeded && data.message) {
                    // show success
                    setErrorMessage('');
                    setMessageBarChoice('success');

                    if (props.addReplication) {
                        props.addReplication();
                    }
                } else if (!data.response.succeeded) {
                    // show error
                    setErrorMessage(
                        data.response.message ||
                            'There was an error sending the re-replicate command.'
                    );
                    setMessageBarChoice('error');
                }
            }
            setWorking(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    );

    return capabilities.check(Capability.artifactRereplicateRole) ? (
        <div className="ReplicateFullBuildButton-root">
            <Stack {...stackProps}>
                <ActionButton
                    onClick={publishPromise}
                    initialButtonText={'Replicate Build'}
                    initialButtonIcon={'Sync'}
                    showConfirmationPopup={true}
                    modalText={
                        'Are you sure you want to re-replicate this build?'
                    }
                    isMenuItem={props.isMenuItem}
                />
                {messageBarChoice === 'error' && (
                    <ErrorExample resetChoice={resetChoice} />
                )}
                {messageBarChoice === 'success' && (
                    <SuccessExample resetChoice={resetChoice} />
                )}
                {working && (
                    <Spinner
                        label="Working..."
                        ariaLive="assertive"
                        labelPosition="right"
                        size={SpinnerSize.large}
                    />
                )}
            </Stack>
        </div>
    ) : null;
};
