import { IColumn } from '@fluentui/react';

export const BuildColumns: IColumn[] = [
    {
        key: 'buildInstanceCorrelationId',
        name: 'Id',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'buildInstanceCorrelationId',
    },
    {
        key: 'service',
        name: 'Service',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'serviceName',
    },
    {
        key: 'projectName',
        name: 'Project',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'projectName',
    },
    {
        key: 'repo',
        name: 'Repo',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'repoName',
    },
    {
        key: 'uniqueIdentifier',
        name: 'Unique Identifer',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        fieldName: 'uniqueIdentifier',
    },
    {
        key: 'buildCvrpManifestRelativePath',
        name: 'Manifest',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        fieldName: 'buildCvrpManifestRelativePath',
    },
    {
        key: 'environments',
        name: 'Environments',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        fieldName: 'environments',
    },
    {
        key: 'createdOn',
        name: 'Created On',
        minWidth: 160,
        maxWidth: 160,
        isResizable: true,
        fieldName: 'createdOn',
    },
];
