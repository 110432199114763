import {
    DetailsListLayoutMode,
    IColumn,
    IconButton,
    SelectionMode,
    ShimmeredDetailsList,
    IDetailsRowProps,
    IRenderFunction,
    DetailsRow,
    FontSizes,
    Icon,
    Stack,
} from '@fluentui/react';
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useContext, useState } from 'react';
import { ServiceType } from '../../../../ABSM/models/ServiceType';
import { Capability } from '../../../../Common/components/Capabilities/capability';
import { CapabilityContext } from '../../../../Common/components/Capabilities/CapabilityContext';
import { FilterBox } from '../../../../Common/components/FilterBox';
import {
    ExternalLink,
    InternalLink,
} from '../../../../Common/components/Links';
import { Guid } from '../../../../Common/models/Guid';
import { dateLocaleStringWithTimeZone } from '../../../../Common/util/DateUtils';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import {
    columnSort,
    commonGridStyle,
    filterList,
} from '../../../../Common/util/tableUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { TextWithTooltip } from '../../../../Common/components/TextWithTooltip';
import { Build } from '../../../models/Build';
import { getAdoRepoUrl, getBuildDetailsPath } from '../../../util/BuildUtils';
import { CompletionIndicatorIcon } from '../../MultistageReleaseData/Display/Status/CompletionIndicatorIcon';
import { AgrmContextButton } from '../../Deployment/AgrmContextButton';
import { AgrmType } from '../../../api/AGRMApi';
import { RestoreBuildButton } from './RestoreBuildButton';
import { RestartAgrmProcessButton } from '../../Deployment/RestartAgrmProcessButton';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../../Common/api/ABHub/ABHubGlobalConfig';
import { Deployment } from '../../../models/Deployment';
import { BuildMetadata } from './BuildMetadata';
import { DetailsPanelTextBox } from '../../MultistageReleaseData/Display/DetailsPanelTextBox';

export interface IBuildInstanceListProps {
    list: Build[];
    isLoaded: boolean;
    setSelectedBuild: Dispatch<SetStateAction<Deployment | Build | undefined>>;
    showPanel: () => void;
    serviceType?: ServiceType;
    serviceId?: Guid;
    showFilterBox?: boolean;
    backgroundColor?: string;
}

export const BuildInstanceList: React.FC<IBuildInstanceListProps> = (
    props: IBuildInstanceListProps
) => {
    const [columnsList, setColumnsList] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<Build[]>([]); //full sorted list
    const [items, setItems] = useState<Build[]>([]); // current version of the list - filtered or sorted

    const themeContext = useContext(ThemeContext);
    const theme = getThemeFromString(themeContext.themeName);

    const restartBuildFlag = getConfig(
        GlobalConfigProperties.RestartAgrmActive
    );

    //need this because the onColumnClick function would only use the filterValue
    //from when it was instantiated
    const filterValueRef = useRef<string>();

    const onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        // Sort the entire list, then reapply the filter
        const sortedBuilds = columnSort(column, columns, props.list);
        setColumnsList(sortedBuilds.newColumns);
        setSortedItems(sortedBuilds.newItems);
        filterList(
            filterItems,
            setItems,
            sortedBuilds.newItems,
            filterValueRef.current || ''
        );
    };

    const getUniqueIdentifier = (
        branch: string | undefined,
        buildNumber: string | undefined,
        componentName: string | undefined
    ) => {
        if (componentName !== undefined && componentName.trim()) {
            return branch + '/' + buildNumber + '/' + componentName;
        } else {
            return branch + '/' + buildNumber;
        }
    };

    // Use capabilities context
    const capabilities = useContext(CapabilityContext);
    // Only show ADO links in public cloud
    const includeExternalLink = capabilities.check(Capability.public);

    const onViewDetailsClick = (build: Build) => {
        props.setSelectedBuild(build);
        props.showPanel();
    };

    const columns: IColumn[] = [
        {
            key: 'showMetadata',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'statusIndicator',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'flightData',
            name: '',
            minWidth: 20,
            maxWidth: 20,
        },
        {
            key: 'buildNumber',
            name: 'Build Number',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            fieldName: 'buildNumber',
            onColumnClick: onColumnClick,
        },
        {
            key: 'branch',
            name: 'Branch',
            minWidth: 160,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'branch',
            onColumnClick: onColumnClick,
        },
        {
            key: 'componentName',
            name: 'Component Name',
            minWidth: 160,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'componentName',
            onColumnClick: onColumnClick,
        },
        {
            key: 'repoName',
            name: 'Repository',
            minWidth: 160,
            maxWidth: 200,
            isResizable: true,
            fieldName: 'repoName',
            onColumnClick: onColumnClick,
        },
        {
            key: 'environment',
            name: 'Environment',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onColumnClick: onColumnClick,
        },
        {
            key: 'restoreBuild',
            name: '',
            minWidth: 60,
            maxWidth: 60,
        },
        {
            key: 'moreEllipsis',
            name: '',
            minWidth: 20,
            maxWidth: 20,
            onColumnClick: onColumnClick,
        },
    ];
    const toggleShowMetadata = (item: Build) => {
        item.showMetadata = !item.showMetadata;
        setItems(items.concat([]));
    };

    const renderReleaseColumn = (
        item: Build,
        index?: number,
        column?: IColumn
    ) => {
        const fieldContent = item[column?.fieldName as keyof Build] as string;

        switch (column?.key) {
            case 'showMetadata':
                return (
                    item.buildMetadataItems.size > 0 && (
                        <IconButton
                            iconProps={{
                                iconName: item.showMetadata
                                    ? 'ChevronDownMed'
                                    : 'ChevronRightMed',
                            }}
                            onClick={() => toggleShowMetadata(item)}
                        />
                    )
                );
            case 'statusIndicator':
                return (
                    <CompletionIndicatorIcon
                        completionIndicator={
                            item.currentReplication?.completionIndicator ??
                            'Unknown'
                        }
                        completionIndicatorString={
                            item.currentReplication
                                ?.completionIndicatorString ?? 'Unknown'
                        }
                        theme={theme}
                    />
                );
            case 'flightData':
                return (
                    <IconButton
                        iconProps={{
                            iconName: 'AirplaneSolid',
                        }}
                        onClick={() => onViewDetailsClick(item)}
                    />
                );
            case 'buildNumber':
                return (
                    <InternalLink
                        value={item.buildNumber || 'Build'}
                        url={
                            '#' +
                            getBuildDetailsPath(item.buildInstanceCorrelationId)
                        }
                        title="View build details"
                    />
                );

            case 'branch':
                return <div>{item.buildBranch}</div>;

            case 'componentName':
                return <div>{item.buildComponentName}</div>;

            case 'repoName':
                return (
                    <div>
                        {item.repoName}
                        {includeExternalLink && (
                            <ExternalLink
                                value="ADO"
                                url={getAdoRepoUrl(
                                    item.accountName || '',
                                    item.projectName || '',
                                    item.repoName || ''
                                )}
                                title="Open repo in Azure DevOps"
                            />
                        )}
                    </div>
                );

            case 'environment':
                if (item.cleanedFromShareDate) {
                    const message =
                        item.currentReplication?.environmentId +
                        ' - Deleted from the share per retention policy on ' +
                        dateLocaleStringWithTimeZone(item.cleanedFromShareDate);
                    return (
                        <Stack horizontal>
                            <Icon
                                iconName={'StatusErrorFull'}
                                style={{
                                    color: theme.semanticColors.errorIcon,
                                }}
                            />
                            <div>{message}</div>
                        </Stack>
                    );
                }

                return <div>{item.currentReplication?.environmentId}</div>;

            case 'restartBuild':
                return (
                    restartBuildFlag && (
                        <RestartAgrmProcessButton
                            uniqueIdentifier={getUniqueIdentifier(
                                item.buildBranch,
                                item.buildNumber,
                                item.buildComponentName
                            )}
                            type={AgrmType.Build}
                        />
                    )
                );

            case 'restoreBuild':
                return (
                    <RestoreBuildButton
                        uniqueIdentifier={getUniqueIdentifier(
                            item.buildBranch,
                            item.buildNumber,
                            item.buildComponentName
                        )}
                    />
                );

            case 'moreEllipsis':
                return (
                    <AgrmContextButton
                        uniqueIdentifier={getUniqueIdentifier(
                            item.buildBranch,
                            item.buildNumber,
                            item.buildComponentName
                        )}
                        type={AgrmType.Build}
                    />
                );

            default:
                return <span>{fieldContent}</span>;
        }
    };

    const renderBuildRow: IRenderFunction<IDetailsRowProps> | undefined = (
        props?: IDetailsRowProps | undefined
    ) => {
        if (props) {
            const item = props.item as Build;
            return (
                <div>
                    <DetailsRow {...props} />
                    <BuildMetadata item={item} />
                </div>
            );
        }

        return <></>;
    };

    //filterFunction
    const filterItems = (i: Build, newValue: string) => {
        return (
            i.buildNumber?.toLowerCase().includes(newValue) ||
            i.buildBranch?.toLowerCase().includes(newValue) ||
            i.repoName?.toLowerCase().includes(newValue) ||
            true
        );
    };

    // Remove the showMetadata column to prevent empty space next to deployment
    useEffect(() => {
        items.map((item) => {
            if (item.buildMetadataItems.size === 0) {
                setColumnsList(
                    columnsList.filter(
                        (column) => column.key !== 'showMetadata'
                    )
                );
            }
        });
    }, [items]);

    // When parent component sends new data, refresh my list accordingly
    useEffect(() => {
        // Default sort by latest timestamp
        let builds = props.list;
        builds.sort((s1, s2) => {
            if (s1.currentReplication && s2.currentReplication) {
                return new Date(
                    dateLocaleStringWithTimeZone(
                        s1.currentReplication.createdOn
                    )
                ) >
                    new Date(
                        dateLocaleStringWithTimeZone(
                            s2.currentReplication.createdOn
                        )
                    )
                    ? -1
                    : 1;
            }
            return 0;
        });
        setColumnsList(columns);
        setItems(builds);
        setSortedItems(builds);
        // needs columns
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.list, props.isLoaded]);

    return (
        <div className="BuildInstanceList-root">
            {props.showFilterBox && (
                <FilterBox
                    style={{ backgroundColor: props.backgroundColor }}
                    items={sortedItems}
                    setItems={setItems}
                    filterFunc={filterItems}
                    filterValueRef={filterValueRef}
                />
            )}
            <ShimmeredDetailsList
                className={commonGridStyle(theme, props.backgroundColor)}
                items={items}
                columns={columnsList}
                onRenderItemColumn={renderReleaseColumn}
                selectionMode={SelectionMode.none}
                onRenderRow={renderBuildRow}
                layoutMode={DetailsListLayoutMode.justified}
                enableShimmer={!props.isLoaded}
                shimmerLines={10}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Builds"
            />
            {props.isLoaded && !props.list.length && <p>No data found.</p>}
        </div>
    );
};
