import React, { useCallback, useContext, useState, useEffect } from 'react';
import { CancelToken } from 'axios';
import { usePromise } from '../../../Common/hooks/usePromise';
import {
    AgrmType,
    checkAgrmRestartPossible,
    checkValidCloudOrEnvironment,
    restartAgrmProcess,
} from '../../api/AGRMApi';
import { CapabilityContext } from '../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../Common/components/Capabilities/capability';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../Common/api/ABHub/ABHubGlobalConfig';
import { Icon, ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { IconActionButton } from '../../../Common/components/IconActionButton';
import { getThemeFromString } from '../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../HubLayout/models/ThemeContext';
import { emitUserActionMetric } from '../../../Common/util/metricsUtil';
import {
    UserAction,
    ActionResult,
} from '../../../Common/Enums/Metrics/MetricEnums';

interface IRestartAgrmProcessButtonProps {
    uniqueIdentifier: string;
    type: AgrmType;
    highSideReset?: boolean;
}

export const RestartAgrmProcessButton: React.FC<IRestartAgrmProcessButtonProps> =
    (props: IRestartAgrmProcessButtonProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);

        const capabilities = useContext(CapabilityContext);
        const [validEnv, setValidEnv] = useState<boolean>(true);
        const [restartTooltipMessage, setRestartTooltipMessage] = useState<
            string | undefined
            >('');
        const [restartAllowed, setRestartAllowed] = useState<boolean>(false);
        const [toolTipTextColor, setToolTipTextColor] =
            useState<string>(theme.semanticColors.errorText);
        const [restartSuccessful, setRestartSuccessful] =
            useState<boolean>(false);

        const restartTerm = props.type === 'Build' ? 'revalidate' : 'restart';
        const typeToLower =
            props.type[0].toLowerCase() +
            props.type.slice(1, props.type.length);

        const [data, error, isLoaded] = usePromise(
            useCallback(
                async (cancelToken?: CancelToken) => {
                    if (props.uniqueIdentifier) {
                        const result = await checkAgrmRestartPossible(
                            props.uniqueIdentifier,
                            props.type,
                            cancelToken
                        );
                        return result;
                    } else {
                        throw new Error('Restart Check to AGRM API failed.');
                    }
                },
                [props.uniqueIdentifier, props.type]
            ),
            true
        );

        useEffect(() => {
            if (error) {
                setToolTipTextColor(theme.palette.red);
                setRestartTooltipMessage(
                    'Unable to restart this ' +
                        typeToLower +
                        ' at this time. There was an error checking the status of the deployment or build validation.'
                );
            } else if (data && !data.data) {
                setToolTipTextColor(theme.palette.red);
                setRestartTooltipMessage(
                    'The ' +
                        typeToLower +
                        ' cannot be restarted at this time, as it is likely already being processed by SRM.'
                );
            } else if (data && data.data) {
                setToolTipTextColor(theme.palette.neutralDark);
                if (props.type === AgrmType.Build) {
                    setRestartTooltipMessage('Click to revalidate build.');
                } else {
                    setRestartTooltipMessage('Click to redeploy stage.');
                }
            }
        }, [
            data,
            error,
            props.type,
            typeToLower,
            theme.palette.red,
            theme.palette.neutralDark,
        ]);

        useEffect(() => {
            if (data && data.data && !restartSuccessful) {
                setRestartAllowed(data.data);
            } else {
                setRestartAllowed(false);
            }
        }, [data, error, restartSuccessful]);

        const environment = getConfig(GlobalConfigProperties.Environment);
        const cloud = getConfig(GlobalConfigProperties.Cloud);

        // builds dont have reset feedback so set message to empty string if its build type
        const restartMessage =
            props.type === AgrmType.Build
                ? 'After clicking the "Revalidate Build" button, please wait a few minutes for the process to complete. To check the status of the revalidation, you can retrieve the General Build Data.'
                : props.highSideReset
                ? 'This deployment has been restarted one or more times, and confirmation has been received lowside that AGRM is processing or has processed one or more of the restarts.'
                : 'This deployment has not yet been restarted. If you recently restarted this deployment, this status will update once we receive confirmation that AGRM successfully initiated the restart process.';
        const tooltipId = useId('restart-tooltip');
        const hostStyles: Partial<ITooltipHostStyles> = {
            root: {
                display: 'inline-block',
            },
        };
        const infoButtonStyles = {
            root: {
                paddingLeft: '5px',
            },
        };

        useEffect(() => {
            try {
                checkValidCloudOrEnvironment(cloud, environment);
            } catch {
                setValidEnv(false);
            }
        }, [cloud, environment]);

        // Setup Restart Agrm Process
        const publishPromise = useCallback(
            async (cancelToken?: CancelToken) => {
                setRestartAllowed(false);

                var restartResult = ActionResult.FAILED;

                if (props.uniqueIdentifier) {
                    const result = await restartAgrmProcess(
                        props.uniqueIdentifier,
                        props.type,
                        cancelToken
                    );

                    if (result.succeeded) {
                        setRestartTooltipMessage(
                            'Successfully restarted ' +
                                props.type.toLowerCase() +
                                ' with id ' +
                                props.uniqueIdentifier
                        );
                        setRestartSuccessful(true);
                        setToolTipTextColor(theme.palette.blue);
                        restartResult = ActionResult.SUCCESS;
                    } else {
                        setRestartTooltipMessage(result.message);
                        setToolTipTextColor(theme.palette.red);
                        setRestartSuccessful(false);
                        setRestartAllowed(true);
                    }

                    return result;
                } else {
                    setRestartSuccessful(false);
                    setRestartAllowed(true);
                    setRestartTooltipMessage(
                        'Failed to restart.Please refer to the logging for further information.'
                    );
                    setToolTipTextColor(theme.palette.red);
                }

                emitUserActionMetric(
                    UserAction.RESTART_SRM_PROCESS,
                    props.type,
                    restartResult,
                    process.env.REACT_APP_BUILD_VERSION
                );
            },
            [
                props.type,
                props.uniqueIdentifier,
                theme.palette.blue,
                theme.palette.red,
            ]
        );

        return capabilities.check(Capability.srmAdminRole) ? (
            <div className="RestartAgrmProcessButton-root">
                {validEnv && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconActionButton
                            setDisabled={!isLoaded || !restartAllowed}
                            setDisabledOnSuccess={true}
                            toolTipText={restartTooltipMessage}
                            toolTipTextColor={toolTipTextColor}
                            onClick={publishPromise}
                            initialButtonText={'Restart ' + props.type}
                            initialButtonIcon={'ReceiptCheck'}
                            showConfirmationPopup={true}
                            iconName={'RepeatAll'}
                            modalText={
                                'Are you sure you want to ' +
                                restartTerm +
                                ' this ' +
                                typeToLower +
                                '?'
                            }
                            differentiator={props.uniqueIdentifier}
                        />
                        <TooltipHost
                            content={restartMessage}
                            id={tooltipId}
                            styles={hostStyles}
                        >
                            <Icon iconName="Info" styles={infoButtonStyles} />
                        </TooltipHost>
                    </div>
                )}
            </div>
        ) : null;
    };
