import React, { useContext, useEffect, useState } from 'react';
import { Build } from '../../../../models/Build';
import { PageTitle } from '../../../../../HubLayout/components/PageLayout/PageTitle';
import { BuildMetrics } from '../BuildMetrics';
import {
    DefaultEffects,
    IconButton,
    IStackTokens,
    mergeStyleSets,
    Stack,
    Text,
} from '@fluentui/react';
import { ThemeContext } from '../../../../../HubLayout/models/ThemeContext';
import { getThemeFromString } from '../../../../../Common/util/localStorageUtils';
import { DataLabel } from '../../../../../HubLayout/components/Utilities/DataLabel';
import { dateLocaleStringWithTimeZone } from '../../../../../Common/util/DateUtils';
import { MobileArtifactInfo } from './MobileArtifactInfo';
import { getAdoRepoUrl } from '../../../../util/BuildUtils';

interface IMobileBuildInstanceDetailsPageProps {
    data: Build | undefined;
    isLoaded: boolean;
}

export const MobileBuildInstanceDetailsPage: React.FC<IMobileBuildInstanceDetailsPageProps> =
    (props: IMobileBuildInstanceDetailsPageProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);

        const styles = mergeStyleSets({
            metadataPanel: {
                backgroundColor:
                    themeContext.themeName === 'dark'
                        ? theme.palette.neutralLighter
                        : theme.palette.themeLighterAlt,
                boxShadow: DefaultEffects.elevation4,
                padding: 5,
                marginBottom: 10,
                color: theme.palette.neutralSecondary,
                selectors: {
                    table: {
                        width: '100%',
                    },
                    td: {
                        padding: 4,
                    },
                },
            },
            label: {
                width: 120,
                fontWeight: 600,
                color: theme.palette.neutralPrimary,
            },
        });

        const stackTokens: IStackTokens = { childrenGap: 10 };

        const maxHashLength = 8;
        const truncateHash = (hash: string) => {
            return hash.length > maxHashLength
                ? hash.substring(0, maxHashLength) + '...'
                : hash;
        };

        return (
            <div className="MobileBuildInstanceDetailsPage-root">
                <PageTitle
                    title={
                        'Build Instance Details' +
                        (props.data?.buildNumber
                            ? ' (Build Number: ' + props.data?.buildNumber + ')'
                            : '')
                    }
                    subTitle="Build details, artifacts list, and status log"
                />

                {props.isLoaded && (
                    <div>
                        {/* PageBreadcrumbTrail */}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                padding: '0px 6px',
                                alignItems: 'center',
                            }}
                            onClick={() => {
                                if (
                                    props.data?.accountName &&
                                    props.data?.projectName &&
                                    props.data?.repoName
                                ) {
                                    const url = getAdoRepoUrl(
                                        props.data.accountName,
                                        props.data.projectName,
                                        props.data.repoName
                                    );
                                    window.open(url, '_blank');
                                }
                            }}
                        >
                            <IconButton iconProps={{ iconName: 'Repo' }} />
                            <Text variant="medium">{props.data?.repoName}</Text>
                        </div>

                        {/* BuildMetrics */}
                        <div
                            style={{
                                margin: '12px 8px',
                            }}
                        >
                            <BuildMetrics data={props.data} />
                        </div>
                        {/* BuildInstanceDetails */}
                        <div>
                            {props.data && (
                                <div
                                    className={styles.metadataPanel}
                                    style={{
                                        padding: '16px',
                                    }}
                                >
                                    <DataLabel
                                        label={'Id: '}
                                        value={
                                            props.data
                                                .buildInstanceCorrelationId
                                        }
                                    />

                                    <DataLabel
                                        label={'Service: '}
                                        value={
                                            props.data.serviceTreeName ||
                                            props.data.serviceTreeId
                                        }
                                    />

                                    <DataLabel
                                        label={'Project: '}
                                        value={props.data.projectName}
                                    />

                                    <DataLabel
                                        label={'Repo: '}
                                        value={props.data.repoName}
                                    />

                                    <DataLabel
                                        label={'Unique Identifier: '}
                                        value={
                                            props.data.buildComponentName
                                                ? `${props.data.buildBranch}/${props.data.buildNumber}/${props.data.buildComponentName}`
                                                : `${props.data.buildBranch}/${props.data.buildNumber}`
                                        }
                                    />

                                    <DataLabel
                                        label={'Manifest (Hash): '}
                                        value={`${
                                            props.data
                                                .buildCvrpManifestRelativePath ||
                                            ''
                                        } (${
                                            truncateHash(
                                                props.data.buildManifestHash ??
                                                    ''
                                            ) || 'N/A'
                                        })`}
                                    />

                                    <DataLabel
                                        label={'Created On: '}
                                        value={dateLocaleStringWithTimeZone(
                                            props.data.createdOn
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                        {/* ArtifactList */}
                        <Stack
                            tokens={stackTokens}
                            style={{
                                border:
                                    'solid 1px ' +
                                    theme.palette.neutralLighterAlt,
                                background: theme.palette.neutralLighterAlt,
                                padding: '10px',
                                margin: '10px 0px',
                            }}
                        >
                            <Text variant="medium">Hydration Manifests</Text>
                            {props.data?.artifacts.map((artifact, index) => {
                                return (
                                    <div>
                                        <div
                                            style={{
                                                border:
                                                    'solid 1px ' +
                                                    theme.palette.neutralLight,
                                            }}
                                        >
                                            <MobileArtifactInfo
                                                artifact={artifact}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </Stack>
                    </div>
                )}
            </div>
        );
    };
