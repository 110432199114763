import React, { useCallback, useContext, useState, useEffect } from 'react';
import { CancelToken } from 'axios';
import { usePromise } from '../../../../Common/hooks/usePromise';
import {
    restoreBuildToShareCheck,
    checkValidCloudOrEnvironment,
    restoreBuildToShare,
} from '../../../api/AGRMApi';
import { CapabilityContext } from '../../../../Common/components/Capabilities/CapabilityContext';
import { Capability } from '../../../../Common/components/Capabilities/capability';
import {
    getConfig,
    GlobalConfigProperties,
} from '../../../../Common/api/ABHub/ABHubGlobalConfig';
import { Icon, ITooltipHostStyles, TooltipHost } from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import { IconActionButton } from '../../../../Common/components/IconActionButton';
import { getThemeFromString } from '../../../../Common/util/localStorageUtils';
import { ThemeContext } from '../../../../HubLayout/models/ThemeContext';
import { emitUserActionMetric } from '../../../../Common/util/metricsUtil';
import {
    UserAction,
    ActionResult,
} from '../../../../Common/Enums/Metrics/MetricEnums';

interface IRestoreBuildButtonProps {
    uniqueIdentifier: string;
    highSideReset?: boolean;
}

export const RestoreBuildButton: React.FC<IRestoreBuildButtonProps> =
    (props: IRestoreBuildButtonProps) => {
        const themeContext = useContext(ThemeContext);
        const theme = getThemeFromString(themeContext.themeName);

        const capabilities = useContext(CapabilityContext);
        const [validEnv, setValidEnv] = useState<boolean>(true);
        const [restoreTooltipMessage, setRestoreTooltipMessage] = useState<
            string | undefined
        >('');
        const [restoreAllowed, setRestoreAllowed] = useState<boolean>(false);
        const [toolTipTextColor, setToolTipTextColor] =
            useState<string>(theme.semanticColors.errorText);
        const [restoreSuccessful, setRestoreSuccessful] =
            useState<boolean>(false);



        const [data, error, isLoaded] = usePromise(
            useCallback(
                async (cancelToken?: CancelToken) => {
                    if (props.uniqueIdentifier) {
                        const result = await restoreBuildToShareCheck(
                            props.uniqueIdentifier,
                            cancelToken
                        );
                        return result;
                    } else {
                        throw new Error('Restore Check to AGRM API failed.');
                    }
                },
                [props.uniqueIdentifier]
            ),
            true
        );

        useEffect(() => {
            if (error) {
                setToolTipTextColor(theme.palette.red);
                setRestoreTooltipMessage(
                    'Unable to restore this build at this time. There was an error checking the status of the build.'
                );
            } else if (data && !data.data) {
                setToolTipTextColor(theme.palette.red);
                setRestoreTooltipMessage(
                    'The build cannot be restored at this time, as it is likely already being processed by SRM.'
                );
            } else if (data && data.data) {
                setToolTipTextColor(theme.palette.neutralDark);
                setRestoreTooltipMessage('Click to restore build.');
            
            }
        }, [
            data,
            error,
            theme.palette.red,
            theme.palette.neutralDark,
        ]);

        useEffect(() => {
            if (data && data.data && !restoreSuccessful) {
                setRestoreAllowed(data.data);
            } else {
                setRestoreAllowed(false);
            }
        }, [data, error, restoreSuccessful]);

        const environment = getConfig(GlobalConfigProperties.Environment);
        const cloud = getConfig(GlobalConfigProperties.Cloud);

        useEffect(() => {
            try {
                checkValidCloudOrEnvironment(cloud, environment);
            } catch {
                setValidEnv(false);
            }
        }, [cloud, environment]);

        // Setup Restore Agrm Process
        const publishPromise = useCallback(
            async (cancelToken?: CancelToken) => {
                setRestoreAllowed(false);

                var restoreResult = ActionResult.FAILED;

                if (props.uniqueIdentifier) {
                    const result = await restoreBuildToShare(
                        props.uniqueIdentifier,
                        cancelToken
                    );

                    if (result.succeeded) {
                        setRestoreTooltipMessage(
                            'Successfully started download for build with id ' +
                            props.uniqueIdentifier
                        );
                        setRestoreSuccessful(true);
                        setToolTipTextColor(theme.palette.blue);
                        restoreResult = ActionResult.SUCCESS;
                    } else {
                        setRestoreTooltipMessage(result.message);
                        setToolTipTextColor(theme.palette.red);
                        setRestoreSuccessful(false);
                        setRestoreAllowed(true);
                    }

                    return result;
                } else {
                    setRestoreSuccessful(false);
                    setRestoreAllowed(true);
                    setRestoreTooltipMessage(
                        'Failed to restore. Please refer to the logging for further information.'
                    );
                    setToolTipTextColor(theme.palette.red);
                }

                emitUserActionMetric(
                    UserAction.RESTORE_BUILD,
                    'build',
                    restoreResult,
                    process.env.REACT_APP_BUILD_VERSION
                );
            },
            [
                props.uniqueIdentifier,
                theme.palette.blue,
                theme.palette.red,
            ]
        );

        return capabilities.check(Capability.srmAdminRole) ? (
            <div className="RestoreBuildButton-root">
                {validEnv && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconActionButton
                            setDisabled={!isLoaded || !restoreAllowed}
                            setDisabledOnSuccess={true}
                            toolTipText={restoreTooltipMessage}
                            toolTipTextColor={toolTipTextColor}
                            onClick={publishPromise}
                            initialButtonText={'Restore build'}
                            initialButtonIcon={'ReceiptCheck'}
                            showConfirmationPopup={true}
                            iconName={'Download'}
                            modalText={
                                'Are you sure you want to restore this build?'
                            }
                            differentiator={props.uniqueIdentifier}
                        />
                    </div>
                )}
            </div>
        ) : null;
    };
